.results {
	&__table {
		width: 100%;
		border-collapse: collapse;
		border: 1px solid #CDCDCD; }
	&__filter {
		border: 1px solid #CDCDCD;
		border-bottom: none;
		background-color: #EAE9E8;
		padding: 10px 15px 5px 15px;
		+ .results__table .results__listheader {
			border-top: 0; } }
	&__listheader {
		font-size: 13px;
		color: #4D4641;
		border: 1px solid #CDCDCD;
		&__collapse {
			width: 50px; }
		&__sort {
			display: inline-block;
			vertical-align: middle;
			margin-left: 10px; }
		th {
			padding: 10px 0 8px;
			font-weight: normal;
			background-color: #EAE9E8;
			line-height: 1; } }
	&__item {
		> td {
			padding-top: 25px;
			padding-bottom: 15px;
			padding-right: 15px;
			background-color: #fff;
			border-bottom: 1px solid #D2D5D8;
			white-space: nowrap;
			overflow-wrap: break-word;
			word-wrap: break-word;
			word-break: break-word;
			hyphens: auto;
			vertical-align: top;
			&:first-child {
				padding-left: 10px;
				.circle {
					top: -3px; } }
			&:last-child {
				padding-right: 0;
				padding-top: 15px; } }
		&__collapse {
			@include size(0);
			border-top: 7px solid transparent;
			border-bottom: 7px solid transparent;
			border-left: 12px solid #888785;
			margin: 4px auto 0;
			cursor: pointer;
			display: inline-block;
			&:hover {
				border-left-color: $c-main; } }
		&__icons {
			@include flex();
			.circle {
				margin-right: 10px; } }
		&__iconslist {
			margin-top: 10px;
			&__row {
				@include flex(flex-start, center); }
			.circle {
				margin: 5px 10px 5px 0; }
			p {
				margin: 0; } }
		&__note {
			@include flex(flex-start, center);
			width: 400px;
			white-space: normal;
			&__message {
				margin-left: 5px;
				font-size: 16px; }
			&--full {
				display: none; } }
		&__actions {
			width: 235px;
			.btn {
				margin-right: 5px;
				min-width: 107px;
				text-align: center; }
			form {
				display: inline; } }
		&:hover > td {
			background-color: #f9f9f9; }
		&.is-open {
			> td {
				background-color: #E8E8E8;
				padding-bottom: 70px; }
			.results__item {
				&__collapse {
					transform: rotate(90deg); }
				&__note {
					&--preview {
						display: none; }
					&--full {
						display: block; } } } }
		&--deny {
			> td {
				background-color: #F5D5DA; }
			&:hover > td,
			&.is-open > td {
				background-color: #F4CCD3; } } } }
