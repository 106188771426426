.event {
	&__header {
		background-color: #F9F9F9;
		padding: 30px 30px 20px;
		border-bottom: 1px solid #D7D7D7; }
	&__table {
		width: 100%;
		&__label {
			font-size: 13px;
			color: #4D4641;
			padding-bottom: 5px; }
		&__value {
 }			//
		td {
			white-space: normal;
			overflow-wrap: break-word;
			word-wrap: break-word;
			word-break: break-word;
			hyphens: auto; } }
	&__body {
		padding: 20px 30px; }
	&__message {
		&__options {
			@include flex(flex-start, center);
			flex-wrap: wrap;
			padding-bottom: 20px;
			&__label {
				font-size: 15px;
				color: #4D4641; }
			&__item {
				position: relative;
				overflow: hidden;
				margin-bottom: 0;
				input {
					@include absolute(-20px null null -20px); }
				div {
					font-size: 13px;
					padding: 4px 5px;
					border: 1px solid #fff;
					color: #777777;
					border-radius: 4px;
					margin-left: 10px;
					text-decoration: underline;
					cursor: pointer;
					border-color: #cccccc;
					margin-bottom: 8px;
					&:hover {
						color: $c-main; } }
				input:checked + div {
					color: $c-main;
					border-color: $c-main;
					text-decoration: none; } } }
		&__fields {
			.field {
				max-width: none; }
			.field.hidden {
				display: none; } }
		&__submit {
			@include flex(space-between, center);
			margin-top: 10px;
			&__link {
				color: #4D4641; }
			a ~ a {
				margin-top: 5px;
				display: inline-block; } } } }
