.topbar {
	@include flex(space-between, center);
	padding: 25px 0;
	&__company {
		@include flex(flex-start, center);
		&__logonav {
			margin-right: 15px;
			max-width: 150px;
			max-height: 50px; } }
	&__info {
		font-size: 14px;
		margin-top: 5px; }

	&__profile {
		&__info {
			@include size(24px);
			display: inline-block;
			text-decoration: none;
			border-radius: 50%;
			color: #fff;
			text-align: center;
			line-height: 24px;
			font-size: 11px;
			font-family: arial;
			font-weight: bold;
			position: relative;
			margin-right: 10px; } } }
