.cols {
	@include flex(flex-start);
	flex-wrap: wrap;
	> .cols__item {
		margin-right: 20px;
		margin-bottom: 20px;
		position: relative;
		&--right {
			@include flex(flex-end); } }
	&--6 {
		> .cols__item {
			width: calc(100% / 6 - 40px);
			margin-right: 40px;
			&:nth-child(6n) {
				margin-right: 0; } } }
	&--4 {
		> .cols__item {
			width: calc(100% / 4 - 15px);
			&:nth-child(4n) {
				margin-right: 0; } } }
	&--3 {
		> .cols__item {
			width: calc(100% / 3 - 15px);
			&:nth-child(3n) {
				margin-right: 0; } } }
	&--2 {
		> .cols__item {
			width: calc(100% / 2 - 10px);
			&:nth-child(2n) {
				margin-right: 0; } } }
	&--2-wide-right {
		> .cols__item:first-child {
			width: calc(100% / 4 - 10px);
			&:nth-child(2n) {
				width: calc(300% / 4 - 10px);
				margin-right: 0; } } } }
