.checkbox {
	position: relative;
	margin: 0;
	cursor: pointer;
	input {
		opacity: 0;
		@include absolute(0 null null 0); }
	span {
		display: block;
		@include size(62px, 31px);
		background-color: #CFCFCF;
		border-radius: 20px;
		position: relative;
		@include ts();
		&:after {
			content: '';
			@include size(25px);
			background-color: #fff;
			border-radius: 50%;
			@include absolute(3px null null 3px);
			@include ts(); } }
	input:focus + span {
		border-color: #fff; }
	input:checked + span {
		background-color: #81C537;
		&:after {
			left: 33px; } }
	&:hover {
		span {
			background-color: #b1b1b1; } } }
