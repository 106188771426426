.title {
	margin: 0;
	padding: 0;
	font-weight: normal;
	&--box {
		font-size: 20px;
		font-family: $f-bold;
		margin-bottom: 30px;
		img {
			height: 22px;
			margin-right: 10px;
			position: relative;
			bottom: 1px; }
		&--smb {
			margin-bottom: 10px; } } }
