.circle {
	@include size(24px);
	display: inline-block;
	text-decoration: none;
	background-color: $c-main;
	border-radius: 50%;
	color: #fff;
	text-align: center;
	line-height: 24px;
	font-size: 11px;
	font-family: arial;
	font-weight: bold;
	position: relative;
	img {
		@include absolute-center();
		max-width: 12px;
		filter: invert(100%); }
	&--large {
		@include size(30px);
		line-height: 32px;
		font-size: 32px;
		padding-left: 6px; }

	&:hover {
		background-color: $c-main-hover;
		text-decoration: none;
		color: #fff; } }

input.circle--large {
	@include size(30px);
	line-height: 26px;
	margin-top: 4px;
	padding-left: 5px; }
