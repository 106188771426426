.field {
	background: #FFFFFF;
	border: 1px solid #CFCFCF;
	color: #4D4641;
	border-radius: 5px;
	padding: 10px 15px;
	width: 100%;
	max-width: 330px;
	min-width: 330px;
	outline: 0;
	@include ts();
	&--small {
		min-width: 155px;
		width: 155px; }
	&--full {
		max-width: none; }
	&--fill {
		max-width: none;
		min-width: 1px; }
	&--bg {
		background-color: #F8F8F8; }
	&:hover {
		border-color: rgba($c-main, .4); }
	&:focus {
		border-color: $c-main; } }
