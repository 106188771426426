.box {
	border: 1px solid #CDCDCD;
	background-color: #fff;
	padding: 30px 20px;
	margin-bottom: 20px;
	&--np {
		padding: 0; }
	&__row {
		padding: 20px;
		&:nth-child(even) {
			background-color: $c-bg; } } }
