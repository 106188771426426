@font-face {
  font-family: "knockout-31";
  src: url("../fonts/knockout-31.woff2") format("woff2"),url("../fonts/knockout-31.woff") format("woff"),url("../fonts/knockout-31.otf") format("opentype");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: "knockout-51";
  src: url("../fonts/knockout-51.woff2") format("woff2"),url("../fonts/knockout-51.woff") format("woff"),url("../fonts/knockout-51.otf") format("opentype");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: "knockout-53";
  src: url("../fonts/knockout-53.woff2") format("woff2"),url("../fonts/knockout-53.woff") format("woff"),url("../fonts/knockout-53.otf") format("opentype");
  font-style: normal;
  font-weight: 400; }

