.login {
	min-height: 100vh;
	overflow: hidden;
	position: relative;
	&__bg {
		@include absolute(0);
		background-size: cover;
		background-position: center center;
		background-image: url(../images/dummy/bg.jpg); }
	&__in {
		position: relative;
		width: 90%;
		margin: 0 auto;
		z-index: 10; }
	&__header {
		color: #fff;
		border-bottom: 1px solid #fff;
		padding: 20px 0;
		&__logo {
			margin-bottom: 5px; } }
	&__list {
		margin: 60px 0;
		overflow: hidden;
		&--dark {
			.login__item {
				color: #000;
				&--add {
					color: $c-main; } } } }
	&__item {
		float: left;
		text-decoration: none;
		color: #fff;
		margin: 30px 50px;
		img {
			margin: 0 auto 10px;
			display: block;
			border-radius: 50%;
			@include ts(); }
		&--add {
			color: $c-main;
			img {
				@include ts(); }
			&:hover {
				text-decoration: none;
				img {
					transform: rotate(90deg); } } }
		&--dark {
			color: #000; }
		&--static {
			float: none;
			margin: 0 auto;
			pointer-events: none; }
		&:hover:not(.login__item--add) {
			text-decoration: none;
			color: currentColor;
			img {
				transform: translate(0, -10px); } } } }
