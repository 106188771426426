body {
	margin: 0;
	color: $c-text;
	font-family: $f-primary;
	font-size: $base-font-size;
	line-height: $base-line-height;
	background-color: #F0F0F0;
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	color: $c-text;
	letter-spacing: 0.1px; }

a {
	color: $c-main;
	text-decoration: underline;
	@include ts();
	&:hover {
		color: $c-main-hover; } }

img {
	max-width: 100%;
	height: auto; }

.hidden {
	display: none; }

.upper {
	text-transform: uppercase; }

.center {
	text-align: center; }

.hide {
	display: none; }

@include media($break768M) {
	.hide-mobile {
		display: none !important; } }

@include media($break768) {
	.hide-desktop {
		display: none !important; } }
