.select {
	position: relative;
	display: inline-block;
	&__label {
		margin: 0 10px;
		padding-top: 6px; }
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
		padding: 10px 40px 10px 15px;
		min-width: 180px;
		border-radius: 0;
		display: block;
		outline: 0;
		border-radius: 3px;
		font-size: 14px;
		border: 1px solid #CFCFCF;
		background: #fff;
		user-select: none;
		@include ts(); }
	&:after {
		content: '';
		@include size(0);
		border-left: 4px solid transparent;
		border-right: 4px solid transparent;
		border-top: 7px solid #888785;
		@include absolute(18px 12px null null);
		z-index: 10;
		pointer-events: none; }
	&:hover select {
		border-color: $c-main; }
	&--compact {
		select {
			width: 100px;
			min-width: 0; } }
	&--xs {
		select {
			padding-left: 5px;
			padding-right: 20px;
			min-width: 0; } }
	&--calendar {
		select {
			width: 120px; }
		&:after {
			display: none; }
		&:before {
			content: '';
			@include size(18px);
			background: url(../images/icon-calendar.svg);
			@include absolute(11px 12px null null);
			z-index: 10;
			pointer-events: none; } }
	&--fill {
		width: 100%;
		select {
			width: 100%; } } }
