.logonav {
	position: relative;
	min-width: 150px;
	&__current {
		background-color: #fff;
		border: 1px solid #888785;
		padding: 2px 35px 2px 10px;
		position: relative;
		border-radius: 2px;
		&:after {
			content: '';
			@include size(8px);
			border-left: 2px solid #000;
			border-bottom: 2px solid #000;
			@include absolute(8px 13px null null);
			transform: rotate(-45deg); } }
	&__nav {
		@include absolute(100% 0 null 0);
		background-color: #fff;
		z-index: 10;
		border: 1px solid #888785;
		border-top: 0;
		border-radius: 0 0 2px 2px;
		pointer-events: none;
		opacity: 0;
		transform: translate(0, -5px);
		@include ts();
		&__item {
			padding: 2px 10px;
			display: block;
			border-bottom: 1px solid rgba(#000, .1);
			img {
				@include ts(); }
			&:last-child {
				border-bottom: none; }
			&:hover {
				img {
					transform: translate(5px, 0); } } }
		&__subitem {
			padding-left: 25px;
			background-color: #F0F0F0;
			&:before {
				content: '↳'; } } }

	&:hover {
		.logonav__current {
			border-radius: 2px 2px 0 0; }
		.logonav__nav {
			opacity: 1;
			transform: translate(0, 0);
			pointer-events: auto; } } }
