.sort {
	&__item {
		display: block;
		width: 0;
		height: 0;
		margin: 3px 0;
		color: #7B7B7B;
		&--up {
			border-left: 3px solid transparent;
			border-right: 3px solid transparent;
			border-bottom: 5px solid currentColor; }
		&--down {
			border-left: 3px solid transparent;
			border-right: 3px solid transparent;
			border-top: 5px solid currentColor; }
		&:hover, &__active {
			color: $c-main; } } }
