.notifications {
	@include fixed(50px 30px null null);
	z-index: 100;
	&__item {
		background-color: #fff;
		box-shadow: 0 1px 4px 0 rgba(0,0,0,0.50);
		border-radius: 4px;
		padding: 15px 15px 15px 25px;
		margin-bottom: 20px;
		position: relative;
		width: 250px;
		text-decoration: none;
		color: $c-text;
		&__container {
			text-decoration: none;
			color: $c-text;
			@include flex(space-between);
			@include ts();
			&:hover {
				text-decoration: none;
				color: $c-text; } }
		&__icon {
			@include absolute(-10px null null -10px); }
		&__arrow {
			@include ts(); }
		&__message {
			span {
				color: $c-main;
				font-family: $f-bold; } }
		&:hover {
			text-decoration: none;
			color: $c-text;
			.notifications__item__arrow {
				transform: translate(5px, 0); } }
		&.is-faded {
			opacity: 0;
			pointer-events: none; } } }

