.labels {
	@include flex();
	&__item {
		background-color: $c-main;
		border: 1px solid $c-main;
		color: #fff;
		padding: 3px 10px;
		margin-right: 12px;
		border-radius: 4px;
		position: relative;
		text-decoration: none;
		white-space: nowrap;
		&:first-child {
			margin-left: 9px; }
		&__remove {
			@include absolute(-15px -15px null null);
			border: 2px solid #fff;
			border-radius: 50%;
			cursor: pointer;
			display: none;
			@include ts();
			&:hover {
				transform: rotate(90deg); } }
		&:hover {
			.labels__item__remove {
				display: block; } }
		&--add {
			background-color: #F0F0F0;
			border-color: #CFCFCF;
			color: #000; } }
	&__form {
		white-space: nowrap;
		margin-left: 10px;
		&__field {
			border: none;
			line-height: 26px;
			background-color: transparent;
			border-bottom: 1px solid #979797;
			outline: 0;
			&::placeholder {
				opacity: 1;
				color: #BDBBB9; }
			&:hover,
			&:focus {
				border-bottom: 1px solid $c-main; } }
		&__submit {
			background-color: $c-main;
			border: none;
			text-transform: uppercase;
			font-size: 15px;
			color: #fff;
			border-radius: 4px;
			padding: 2px 6px;
			margin-left: 10px;
			cursor: pointer;
			outline: 0;
			&:hover {
				background-color: $c-main-hover; } } } }
