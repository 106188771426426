.hourstable {
	select {
		margin: 0 7px; }
	&__label {
		margin-top: 8px; }
	.circle {
		vertical-align: -7px;
		&--alone {
			vertical-align: -15px; } }
	&__remove {
		font-size: 31px;
		cursor: pointer;
		color: $c-main;
		border: none;
		background: none;
		width: 1em;
		vertical-align: top; } }
