.tabnav {
	@include flex();
	&__item {
		@include flex(space-between);
		background-color: #AEAEAE;
		border: 1px solid #AEAEAE;
		border-bottom: none;
		color: #000000;
		font-family: $f-bold;
		text-decoration: none;
		font-size: 20px;
		line-height: 1.2;
		padding: 15px 20px;
		margin-right: 2px;
		min-width: 185px;
		position: relative;
		&__circle {
			position: relative;
			left: 10px; }
		&:hover {
			color: #000;
			background-color: #c3c3c3;
			border-color: #c3c3c3;
			text-decoration: none; }
		&.is-active {
			background-color: #EAE9E8;
			color: $c-main;
			border-color: #CDCDCD;
			&:after {
				content: '';
				@include absolute(null 0 -1px 0);
				height: 1px;
				background-color: #EAE9E8; } } }
	&--light {
		.tabnav__item.is-active {
			background-color: #fff;
			&:after {
				background-color: #fff; } } } }
