.profile {
	@include flex(flex-start, center);
	line-height: 1.2;
	&__img {
		margin-right: 10px;
		img {
			border-radius: 50%;
			width: 53px; } }
	&__name {
		font-family: $f-bold; } }
