.crossroad {
	margin: 100px 0;
	&__list {
		li {
			margin: 5px 0; } }
	&__disabled {
		a {
			pointer-events: none;
			color: $c-text-light;
			text-decoration: none; } } }
