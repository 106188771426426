.btn {
	border: none;
	display: inline-block;
	text-decoration: none;
	outline: none;
	line-height: 1.3;
	cursor: pointer;
	@include ts();
	&--textarrow {
		color: $c-main;
		text-decoration: none;
		span {
			text-decoration: underline; }
		img {
			margin-left: 10px;
			@include ts(); }
		&:hover {
			img {
				transform: translate(5px, 0); } }
		&--left {
			img {
				transform: scaleX(-1);
				@include ts(); }
			span {
				text-decoration: underline;
				margin-left: 10px; } } }

	&--primary,
	&--primary--fill {
		border-radius: 3px;
		padding: 12px 15px;
		border: 1px solid $c-main;
		background-color: #fff;
		color: $c-main;
		text-transform: uppercase;
		font-size: 14px;
		img {
			margin-right: 10px;
			position: relative;
			bottom: 1px; } }
	&--primary {
		&:hover {
			color: $c-main-hover;
			border-color: $c-main-hover;
			text-decoration: none; } }
	&--primary--fill {
		background-color: $c-main;
		color: #fff;
		&:hover {
			background-color: $c-main-hover;
			border-color: $c-main-hover;
			color: #fff; } }
	&--wide {
		padding-left: 60px;
		padding-right: 60px; } }
