.popup {
	&__wrapper {
		@include fixed-center();
		z-index: 1000;
		max-width: 590px; }
	&__bg {
		@include fixed(0);
		background-color: #000;
		opacity: 0;
		z-index: 900;
		pointer-events: none;
		@include ts();
		.popup-is-open & {
			opacity: .7;
			pointer-events: auto; } }
	&__inside {
		@include ts(); }
	&__form {
		padding: 20px;
		border-bottom: 1px solid #D7D7D7;
		margin-bottom: 20px; }
	&__header {
		position: relative;
		text-align: center;
		color: #fff;
		line-height: 30px;
		padding: 0 0 13px; }
	&__close {
		font-size: 25px;
		@include size(30px);
		line-height: 32px;
		@include absolute(0 0 null null);
		cursor: pointer;
		transition: .3s ease all;
		&:hover {
			transform: rotate(90deg); } }
	&__title {
		font-size: 20px;
		font-family: $f-bold; }
	&__content {
		background-color: #fff;
		&__bg {
			background-color: #F9F9F9; }
		&__icon {
			width: 20px;
			margin-right: 5px;
			text-align: center;
			padding-bottom: 3px; } }
	&__checkboxes {
		@include flex(center);
		margin: 20px 0;
		&__item {
			margin: 0 20px; } }

	&--hide {
		pointer-events: none;
		display: inline-block;
		.popup__inside {
			transform: scale(.95);
			opacity: 0; } }

	&--thin {
		.popup__wrapper {
			max-width: 420px; } } }
