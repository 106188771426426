.form {
	&__row {
		margin-bottom: 20px;
		@include flex(flex-start, center);
		&:last-child {
			margin-bottom: 0; }
		&--offset {
			margin-top: 40px; }
		&--top {
			align-items: flex-start; }
		&--slim {
			margin-bottom: 10px; } }
	&__label {
		width: 140px;
		img {
			margin-top: -4px; } }
	&__input {
		width: calc(100% - 130px - 50px);
		&--full {
			width: calc(100% - 130px); }
		&__one-of-two {
			width: auto; } }
	&__submit {
		margin-top: 25px;
		margin-bottom: 25px; }
	&__attr {
		@include flex(flex-start, flex-start);
		&__label {
			@include flex(flex-start, center);
			&__value.label {
				margin-right: 20px; } }
		&__labels {
			margin-left: 10px; }
		&__icon {
			width: 22px;
			position: relative;
			img {
				@include absolute-center();
				width: auto; } } } }

