.scheduletable {
	&__table {
		width: 100%;
		border-collapse: collapse;
		border: 1px solid #CDCDCD; }
	&__item {
		vertical-align: top;
		> td {
			padding-top: 20px;
			padding-right: 15px;
			background-color: #fff;
			border-bottom: 1px solid #D2D5D8;
			white-space: nowrap;
			vertical-align: top;
			@include ts();
			&:first-child {
				padding-left: 15px;
				padding-top: 27px; } }
		&__day {
			font-size: 20px;
			line-height: 1.2;
			font-family: $f-bold;
			width: 70px;
			text-transform: uppercase;
			span {
				display: block;
				font-size: 16px;
				font-family: $f-primary; } }
		&:nth-child(even) > td {
			background-color: #F2F2F2; }
		&:last-child {
			> td {
				border-bottom: none; } } }

	&--nb {
		.scheduletable__table {
			border: none; } }
	&--extended {
		.scheduletable__table {
 }			//
		.scheduletable__item {
			&__day {
				position: relative;
				padding-right: 50px;
				&:after {
					content: '';
					display: block;
					height: 40px;
					width: 1px;
					background-color: #BDBBB9;
					@include absolute(15px 20px 0 null); } }
			> td {
				padding-top: 10px;
				padding-bottom: 10px;
				&:nth-child(2) {
					padding-top: 20px;
					overflow: hidden;
					width: 200px; } } } } }
