// Usage:
//@include absolute(top right bottom left)
//@include absolute(top horizontal bottom)
//@include absolute(vertical horizontal)
//@include absolute(offset)

//xamples:
//@include absolute(1px 2px 3px 4px) // position: absolute; top: 1px; right: 2px; bottom: 3px; left: 4px

//@include absolute(1px 2px 3px) // position: absolute; top: 1px; right: 2px; bottom: 3px; left: 2px

//@include absolute(1px 2px) // position: absolute; top: 1px; right: 2px; bottom: 1px; left: 2px

//@include absolute(1px) // position: absolute; top: 1px; right: 1px; bottom: 1px; left: 1px

//@include absolute(1px null null 2px) // position: absolute; top: 1px; left: 2px

//ll the other position mixins behave identically


@mixin absolute($properties: null null null null) {
	@include position(absolute, $properties); }


@mixin fixed($properties: null null null null) {
	@include position(fixed, $properties); }


@mixin relative($properties: null null null null) {
	@include position(relative, $properties); }


@mixin sticky($properties: null null null null) {
	@include position(sticky, $properties); }
