.events {
	border: 1px solid #CDCDCD;
	&__item {
		background-color: #ffffff;
		&__container {
			@include flex(flex-start);
			flex-wrap: nowrap;
			vertical-align: top;
			padding: 20px 15px 0 15px;
			border-bottom: 1px solid #D2D5D8;
			white-space: nowrap;
			vertical-align: top;
			@include ts();
			&__settings {
				width: 100%;
				&__cols {
					@include flex(flex-start);
					width: 100%;
					min-height: 60px;
					.radiofield__list {
						margin-top: 10px; }
					.cols__item {
						margin-bottom: 10px; } }
				&__buttons {
					margin-left: auto;
					width: 210px; } }
			&__day {
				position: relative;
				font-size: 20px;
				line-height: 1.2;
				font-family: $f-bold;
				width: 100px;
				text-transform: uppercase;
				margin-right: 20px;
				border-right: {}
				span {
					display: block;
					font-size: 16px;
					font-family: $f-primary; }
				&:after {
					content: '';
					display: block;
					height: 40px;
					width: 1px;
					background-color: #BDBBB9;
					@include absolute(0px 0 0 null); } } } } }
