.filefield {
	position: relative;
	overflow: hidden;
	margin: 0;
	&__input {
		outline: 0;
		@include absolute(-100px null null -100px);
		opacity: 0; }
	&--fill {
		width: 100%;
		.btn {
			width: 100%;
			text-align: center; } }
	&--image {
	  background: url(../images/transparent.png) repeat;
	  max-width: 150px;
	  max-height: 50px;
	  vertical-align: baseline;
	  &:hover {
	  	background: #555; } } }
