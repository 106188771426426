// Global
$radius: 2px;
$base-font-size: 16px;
$base-line-height: 1.4;

// Fonts
$f-primary: 'knockout-31', 'Arial';
$f-bold:  'knockout-51', 'Arial';

// Colors
$c-main: #C8102E;
$c-secondary: #dc0c23;

$c-main-hover: #f70606;

$c-text: #202020;
$c-text-light: #706f6f;
$c-text-dark: #1f1f1f;

$c-bg: #F4F4F4;
$c-bg-dark: #1f1f1f;

$section-spacing: 30px;

$b-primary: 1px solid #dbdbdb;

// Breakpoints
$break320: '(min-width: 320px)';
$break480: '(min-width: 480px)';
$break768: '(min-width: 768px)';
$break992: '(min-width: 992px)';
$break1200: '(min-width: 1200px)';
$break1400: '(min-width: 1400px)';
$break1600: '(min-width: 1600px)';
$break2560: '(min-width: 2560px)';

$break320M: '(max-width: 319px)';
$break480M: '(max-width: 479px)';
$break768M: '(max-width: 767px)';
$break992M: '(max-width: 991px)';
$break1200M: '(max-width: 1199px)';
$break1400M: '(max-width: 1399px)';
$break1600M: '(max-width: 1599px)';
$break2560M: '(max-width: 2559px)';

$landscape: '(orientation: landscape)';
$portrait: '(orientation: portrait)';

